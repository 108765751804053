import { render, staticRenderFns } from "./headerType01.vue?vue&type=template&id=40c47e5a&scoped=true&"
import script from "./headerType01.vue?vue&type=script&lang=js&"
export * from "./headerType01.vue?vue&type=script&lang=js&"
import style0 from "./headerType01.vue?vue&type=style&index=0&id=40c47e5a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40c47e5a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InputRegion: require('/var/www/html/jayuvillage/components/inputRegion.vue').default,Modal: require('/var/www/html/jayuvillage/components/modal.vue').default,Finder: require('/var/www/html/jayuvillage/components/finder.vue').default})
