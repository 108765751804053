import { render, staticRenderFns } from "./index.vue?vue&type=template&id=790340d8&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=790340d8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "790340d8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loading: require('/var/www/html/jayuvillage/components/Loading.vue').default,HeaderType01: require('/var/www/html/jayuvillage/components/headerType01.vue').default,Quicks: require('/var/www/html/jayuvillage/components/quicks.vue').default,Navigation: require('/var/www/html/jayuvillage/components/navigation.vue').default})
