
import Dropdown from './dropdown.vue'
export default {
    components: {
        Dropdown,
    },
    props: {
        districtContainer: {
            type: Object,
            default: () => { }
        },
        initiationCalled: {
            type: Boolean,
            default: false
        },
        type: {
            default: ""
        },
        state: {
            default: ""
        },
        city: {
            default: ""
        },
        district: {
            default: ""
        },
        district_id: {
            default: ""
        },
        inputClass: {
            default: "m-input-select type02"
        }
    },
    computed: {
        stateSelected() {
            return !!this.stateData;
        },
        citySelected() {
            return !!this.cityData;
        },
        districtSelected() {
            return !!this.districtIdData;
        }
    },
    watch: {
        districtContainer: {
            deep: true,
            handler(newVal) {
                if (Object.keys(newVal).length !== 0) {
                    this.containerFlag = true;
                    this.propState = this.districtContainer.state;
                }

            }
        },
        initiationCalled(newData) {
            if (newData) {
                this.propState = '';
                this.propCity = '';
                this.propDistrict = '';
            }

        }
    },
    data() {
        return {
            containerFlag: false,
            propState: "",
            propCity: "",
            propDistrict: "",
            stateActive: false,
            cityActive: false,
            districtActive: false,
            states: this.state ? [this.state] : [],
            cities: this.city ? [this.city] : [],
            districts: this.district ? [this.district] : [],
            stateData: this.state,
            cityData: this.city,
            districtIdData: this.district_id,

            orders: {
                "서울": 1,
                "경기": 2,
                "인천": 3,
                "부산": 4,
                "대구": 5,
                "광주": 6,
                "대전": 7,
                "울산": 8,
                "세종": 9,
                "강원": 10,
                "충북": 11,
                "충남": 12,
                "전북": 13,
                "전남": 14,
                "경북": 15,
                "경남": 16,
                "제주": 17,
            }
        }
    },

    methods: {
        toggleState() {
            this.stateActive = !this.stateActive;
            this.cityActive = false;
            this.districtActive = false;
        },
        toggleCity() {
            this.cityActive = !this.cityActive;
            this.stateActive = false;
            this.districtActive = false;
        },
        toggleDistrict() {
            this.districtActive = !this.districtActive;
            this.stateActive = false;
            this.cityActive = false;
        },
        changeState(stateData) {
            if (stateData && typeof stateData == 'string') {
                this.stateData = stateData;
            }
            // const data = stateData ? stateData : this.stateData
            this.$axios.get(`/api/cities?state=${this.stateData}`)
                .then(response => {
                    this.cities = response.data.sort(
                        //가나다순 정렬
                        (a, b) => a.localeCompare(b)
                    );

                    this.cityData = '';

                    this.districts = [];

                    this.districtIdData = "";

                    this.change();

                    if (this.containerFlag)
                        this.propCity = this.districtContainer.city;
                });
        },

        changeCity(cityData) {
            if (cityData && typeof cityData == 'string') {
                this.cityData = cityData;
            }
            this.$axios.get(`/api/districts?state=${this.stateData}&city=${this.cityData}`)
                .then(response => {
                    this.districts = response.data.sort(
                        //가나다순 정렬
                        (a, b) => a.district.localeCompare(b.district)

                    );

                    this.districtIdData = "";

                    this.change();

                    if (this.containerFlag)
                        this.propDistrict = this.districtContainer.district;
                });
        },

        change(districtData) {
            if (districtData && typeof districtData == 'number') {
                this.districtIdData = districtData;
            }
            this.$emit("change", {
                state: this.stateData,
                city: this.cityData,
                district: this.districts.find(district => district.id == this.districtIdData),
                district_id: this.districtIdData
            });
        },
    },

    mounted() {
        this.$axios.get("/api/states").then(response => {
            this.states = response.data.sort((a, b) => {
                return this.orders[a] - this.orders[b];
            });
        });

        if (this.state) {
            this.$axios.get(`/api/cities?state=${this.state}`)
                .then(response => {
                    this.cities = response.data;
                });
        }

        if (this.city) {
            this.$axios.get(`/api/districts?state=${this.state}&city=${this.city}`)
                .then(response => {
                    this.districts = response.data;
                });
        }
    }
}
